import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import logo from "../../images/BTTF-LOGO-small.png"

const VModal = styled(Modal)`
    max-width: 900px;
    padding: 0;
    border: 0;
    color: ${props => props.theme.colors.white};

    .modal-content {
        border: 0;
        position: relative;
        border-radius: 0;
        background-color: ${props => props.theme.colors.black};
    }

    .modal-body {
        padding: 1rem;
        border: 0;
        
        @media ${media.sm} {
            padding: 0;
        }
    }
    
    h2, h3 {
        text-align: center;
        color: ${props => props.theme.colors.white};   
    }
    
    h2 {
        @media ${media.xl} {
            font-size: 2.5rem;
        }
    }
    
    p {
        font-size: 1rem !important;
    }
    
    .logo {
        width: 100%;
        height: auto;
        max-width: 220px;
        position: absolute;
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
    }
`

const Table = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 1rem auto;   
   
`

const TableRow = styled.div`
    width: 100%;
    display: flex;
    
    .tableCol {
        width: 50%;
        padding: 0.25rem;
    }   
`

const ButtonClose = styled.button`
    position: absolute;
    background-color: transparent;
    z-index: 10;
    right: 1rem;
    top: -10px;
    width: 30px;
    height: 30px;
    border: 0;
    
    @media (min-width: 992px) {
        width: 50px;
        height: 50px;
    }
    
    &:after,
    &:before {
        content: "";
        width: 3px;
        height: 60%;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

`

const ColumnContainer = styled.div`
    text-align: center;
    @media ${media.sm} {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }
`

const Column = styled.div`
    width: 100%;
        align-self: stretch;
    margin: 0 auto;
    
    .helper {
        padding: 1rem;
        height: 100%;
    }
    
    @media ${media.sm} {
        width: 50%;
    }
    
    @media ${media.lg} {
        width: 45%;
        background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
        padding: 2px;
        
        &:nth-of-type(2) {
            padding: 2px;
        }
        
        .helper {
            background-color: ${props => props.theme.colors.black};
        }
    }
`

class PerformanceModal extends Component {
    render() {
        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>
                {/*<img src={logo} className="logo" alt="Back to the Future the Musical" title="Back to the Future the Musical"/>*/}
                <ModalBody>
                    <h2 className="h1">Performance Schedule</h2>
                    <ColumnContainer>
                        {/* <Column>
                            <div className="helper">
                                <h3>20 AUG – 4 SEP 2021</h3>
                                <Table>
                                    <TableRow>
                                        <div className="tableCol">Tuesday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Wednesday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Thursday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Friday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Saturday</div>
                                        <div className="tableCol">14:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Saturday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                </Table>
                                <p>*Additional performance on Monday 30 August at 19:30</p>
                            </div>
                        </Column> */}
                        {/* <Column>
                            <div className="helper">
                                <Table>
                                    <TableRow>
                                        <div className="tableCol">Monday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Tuesday</div>
                                        <div className="tableCol">No Show</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Wednesday</div>
                                        <div className="tableCol">14:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Wednesday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Thursday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Friday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Saturday</div>
                                        <div className="tableCol">14:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Saturday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Sunday</div>
                                        <div className="tableCol">15:00</div>
                                    </TableRow>

                                </Table>
                            </div>
                        </Column> */}
                        <Column>
                            <div className="helper">
                                <Table>
                                    <TableRow>
                                        <div className="tableCol">Monday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Tuesday</div>
                                        <div className="tableCol">No Show</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Wednesday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Thursday</div>
                                        <div className="tableCol">14:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Thursday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Friday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Saturday</div>
                                        <div className="tableCol">14:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Saturday</div>
                                        <div className="tableCol">19:30</div>
                                    </TableRow>
                                    <TableRow>
                                        <div className="tableCol">Sunday</div>
                                        <div className="tableCol">15:00</div>
                                    </TableRow>
                                </Table>
                                <p>*There will be additional 14.30 performances on Monday 10th April, Monday 29th May, Monday 28th August.</p>
                            </div>
                        </Column>
                    </ColumnContainer>
                </ModalBody>
            </VModal>
        );
    }
}

export default PerformanceModal;