import React, { Component } from "react"
import { Button, Form, Label, FormGroup, Input, FormFeedback } from "reactstrap"
import Amplify, {API} from 'aws-amplify'
import awsconfig from '../../aws-exports'
import styled from "styled-components"
import {media} from "utils/Media"
import StyledButton from "../shared/StyledButton"
import {
    validateRequired,
    validateEmail
} from 'utils/Validations'

Amplify.configure(awsconfig);

const FormStyled = styled(Form) `
    text-align: left;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    
    .form-control {
        border-radius: 0;
        border: 1px solid ${props => props.theme.colors.red};
    }
`
const LabelStyled = styled(Label) `
    font-family: ${props => props.theme.font.family.bold};
    
    .font-weight-normal {
        font-family: ${props => props.theme.font.family.base};
    }
`

class CustomerServicesForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            name: '',
            telephone: '',
            email: '',
            bookingenquiry: '',
            generalenquiry: '',
            sendingFormRequest: false,
            validate: {
                name: '',
                telephone: '',
                email: '',
                enquiry_type: '',
                message: ''
            },
            required: {
                name: '',
                email: ''
            },
            success: false
        }

        // Bind this to methods
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        // Bind this to validation methods
        this.validateRequired = validateRequired.bind(this)
        this.validateEmail = validateEmail.bind(this)

        // Create form ref
        this.form = React.createRef();
        this.successMessage = React.createRef();
    }


    // Method to update field values in state on change
    handleChange(e) {
        const target = e.target
        const name = target.name
        const value = target.value

        let required = this.state.required

        if (required.hasOwnProperty(name)) {
            required[name] = value
        }

        // Update value in state
        this.setState({
            required,
            [name]: value,
        });
    }

    async handleSubmit(e) {
        e.preventDefault();

        //disable the button so we can't send multiple requests
        this.setState({sendingFormRequest: true});

        // Create form data ready for api wrapper call
        let formData = {};
        for (let key in this.state) {
            if (typeof this.state[key] === 'object' && this.state[key].constructor === Object) {
                for (let k in this.state[key]) {
                    formData[key + '[' + k + ']'] = this.state[key][k];
                    //formData.append(key + '[' + k + ']', this.state[key][k])
                }
            } else {
                formData[key] = this.state[key];
                //formData.append(key, this.state[key]);
            }
        }

        let {validate} = this.state
        let errors = false


        for (let key in this.state.validate) {
            if (this.state.required[key] === "") {
                validate[key] = 'has-danger'
                errors = true
            }

            this.setState({validate});
        }

        // return if errors
        if (errors) return

        console.log(formData)

        // let apiName = 'bttfServicesForm2';
        // let path = "/contact"
        // let myInit = { // OPTIONAL
        //     headers: {}, // OPTIONAL
        //     response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        //     queryStringParameters: formData
        // }

        await fetch(`/sendEmail`, {
            method: `POST`,
            body: JSON.stringify(formData),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(res => res.json())
            .then(body => {
              if (body.status === 200 && body.result === "success") {
                this.setState({sendingFormRequest: false});
                this.setState({
                    success: true
                });
              }
            })
            .catch(error => {
                this.setState({sendingFormRequest: false});
              console.log(error)
              return error.response
            })

        // return API.get(apiName, path, myInit).then(response => {
        //     // return response.data
        //     this.setState({sendingFormRequest: false});

        //     console.log(response)

        //     if (response.status === 200 && response.data.result === "success") {
        //         // const domNode = ReactDOM.findDOMNode(this.successMessage.current)

        //         this.setState({
        //             success: true
        //         });

        //         // scrollToElement(domNode, {
        //         //     offset: -120,
        //         //     duration: 500
        //         // });
        //     }
        // }).catch(error => {
        //     this.setState({sendingFormRequest: false});
        //     return error.response
        // })
    }


    render() {
        if (this.state.success) {
            return (
                <p>
                    Thank you for contacting us.
                </p>
            )
        } else {
            return(
                <FormStyled onSubmit={(e) => this.handleSubmit(e)} noValidate ref={this.form}>
                    <FormGroup>
                        <LabelStyled for="name">Full Name</LabelStyled>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={this.state.name}
                            valid={this.state.validate.name === 'has-success'}
                            invalid={this.state.validate.name === 'has-danger'}
                            onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                            }}
                        />
                        <FormFeedback>
                            Your name is required
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <LabelStyled for="email">Email Address</LabelStyled>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            value={this.state.email}
                            valid={this.state.validate.email === 'has-success'}
                            invalid={this.state.validate.email === 'has-danger'}
                            onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                                this.validateEmail(e)
                            }}
                        />
                        <FormFeedback>
                            A valid email address is required
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <LabelStyled for="telephone">Phone Number</LabelStyled>
                        <Input
                            type="text"
                            name="telephone"
                            id="telephone"
                            valid={this.state.validate.telephone === 'has-success'}
                            invalid={this.state.validate.telephone === 'has-danger'}
                            value={this.state.telephone}
                            onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                            }}
                        />
                        <FormFeedback>
                            A valid phone number is required
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <LabelStyled for="message">Booking enquiry <span className="font-weight-normal">(please choose 3 preferred dates and performance times)</span></LabelStyled>
                        <Input
                            type="textarea"
                            name="bookingenquiry"
                            id="bookingenquiry"
                            value={this.state.bookingenquiry}
                            onChange={e => this.handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <LabelStyled for="message">General Enquiry <span className="font-weight-normal">(if it relates to an existing booking please include your reference number)</span></LabelStyled>
                        <Input
                            type="textarea"
                            name="generalenquiry"
                            id="generalenquiry"
                            value={this.state.generalenquiry}
                            onChange={e => this.handleChange(e)}
                        />
                    </FormGroup>
                    <StyledButton className="my-3 mx-auto d-block" button $inverse>
                        Submit
                    </StyledButton>
                </FormStyled>
            )
        }
    }
}

export default CustomerServicesForm