import React from "react"
import Layout from "../../components/Layout/Layout"
import PageHeader from "../../components/shared/PageHeader"
import PerformanceBlock from "../../components/TicketsPage/PerformanceBlock"
import FaqBlock from "../../components/TicketsPage/FaqBlock"
import GroupsBlock from "../../components/TicketsPage/GroupsBlock";
import NowBookingBlock from "../../components/TicketsPage/NowBookingBlock";
import {graphql, StaticQuery} from "gatsby"

const TicketsPage = (props) => (
	<Layout title="Tickets | London | Back to the Future the Musical" description="The official place to buy tickets for Back to the Future The Musical. Now playing at the Adelphi Theatre in London.">
		<PageHeader
			imageXs={props.data.imageXs}
			imageSm={props.data.imageSm}
			imageLg={props.data.imageLg}
			title="Tickets" />
		<NowBookingBlock/>
		<PerformanceBlock/>
		<GroupsBlock/>
		<FaqBlock/>
	</Layout>
)

export default (props) => (
	<StaticQuery
		query={graphql`
            query {
                imageXs: file(relativePath: { eq: "576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSm: file(relativePath: { eq: "991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLg: file(relativePath: { eq: "1920.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
            }
		`}
		render={data => (
			<TicketsPage
				{...props}
				data={data}/>
		)}
	/>
)
