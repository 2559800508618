import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import logo from "../../images/BTTF-LOGO-small.png"

const VModal = styled(Modal)`
    max-width: 900px;
    padding: 0;
    border: 0;
    color: ${props => props.theme.colors.white};

    .modal-content {
        border: 0;
        position: relative;
        border-radius: 0;
        background-color: ${props => props.theme.colors.black};
    }

    .modal-body {
        padding: 0.5rem;
        border: 0;
        
        @media ${media.sm} {
            padding: 0;
        }
    }
    
    h2, h3 {
        text-align: center;
        color: ${props => props.theme.colors.white};   
    }
    
    h2 {
        @media ${media.xl} {
            font-size: 2.5rem;
        }
    }
    
    p {
        font-size: 1rem !important;
    }
    
    .logo {
        width: 100%;
        height: auto;
        max-width: 220px;
        position: absolute;
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
    }
`

const Table = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 0 auto;   
   
   @media ${media.md} {
      margin: 1rem auto;
   }
`

const TableRow = styled.div`
    width: 100%;
    display: flex;
    
    .tableCol {
        width: 50%;
        padding: 0.25rem;
    }   
`

const ButtonClose = styled.button`
    position: absolute;
    background-color: transparent;
    z-index: 10;
    right: 1rem;
    top: -5px;
    width: 30px;
    height: 30px;
    border: 0;
    
    @media (min-width: 992px) {
        width: 50px;
        height: 50px;
    }
    
    &:after,
    &:before {
        content: "";
        width: 3px;
        height: 60%;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

`

const ColumnContainer = styled.div`
    @media ${media.md} {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }
`

const Column = styled.div`
    width: 100%;
        align-self: stretch;
    margin: 0 auto;
    
    @media ${media.md} {
        width: 50%;
    }
    
    @media ${media.lg} {
        background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
        padding: 2px;
    }
    
    .helper {
        height: 100%;
        
        @media ${media.md} {
            padding: 1rem;
        }
        
        @media ${media.lg} {
            background-color: ${props => props.theme.colors.black};
        }
    }
`

class PerformanceXmasModal extends Component {
    render() {
        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>
                {/*<img src={logo} className="logo" alt="Back to the Future the Musical" title="Back to the Future the Musical"/>*/}
                <ModalBody>
                    <h2 className="h1">Christmas Schedule</h2>
                    <ColumnContainer>
                        <Column>
                            <div className="helper">
                                <Table>
                                    <TableRow><div className="tableCol">Sun</div> <div className="tableCol">17</div>	<div className="tableCol">Dec 13:00 & 18:00</div></TableRow>
                                    <TableRow><div className="tableCol">Mon</div> <div className="tableCol">18</div>	<div className="tableCol">Dec No Show</div></TableRow>
                                    <TableRow><div className="tableCol">Tue</div> <div className="tableCol">19</div>	<div className="tableCol">Dec 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Wed</div> <div className="tableCol">20</div>	<div className="tableCol">Dec 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Thu</div> <div className="tableCol">21</div>	<div className="tableCol">Dec 14:30 & 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Fri</div> <div className="tableCol">22</div>	<div className="tableCol">Dec 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Sat</div> <div className="tableCol">23</div>	<div className="tableCol">Dec 14:30 & 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Sun</div> <div className="tableCol">24</div>	<div className="tableCol">Dec 13:00</div></TableRow>
                                    <TableRow><div className="tableCol">Mon</div> <div className="tableCol">25</div>	<div className="tableCol">Dec No Show</div></TableRow>
                                    <TableRow><div className="tableCol">Tue</div> <div className="tableCol">26</div>	<div className="tableCol">Dec No Show</div></TableRow>
                                    <TableRow><div className="tableCol">Wed</div> <div className="tableCol">27</div>	<div className="tableCol">Dec 14:30 & 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Thu</div> <div className="tableCol">28</div>	<div className="tableCol">Dec 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Fri</div> <div className="tableCol">29</div>	<div className="tableCol">Dec 14:30 & 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Sat</div> <div className="tableCol">30</div>	<div className="tableCol">Dec 14:30 & 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Sun</div> <div className="tableCol">31</div>	<div className="tableCol">Dec 15:00</div></TableRow>
                                    <TableRow><div className="tableCol">Mon</div> <div className="tableCol">1</div>	<div className="tableCol">Jan No Show</div></TableRow>
                                    <TableRow><div className="tableCol">Tue</div> <div className="tableCol">2</div>	<div className="tableCol">Jan 14:30 & 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Wed</div> <div className="tableCol">3</div>	<div className="tableCol">Jan 19:30</div></TableRow>
                                    <TableRow><div className="tableCol">Thu</div> <div className="tableCol">4</div>	<div className="tableCol">Jan 19:30</div></TableRow>
                                    
                                </Table>
                            </div>
                        </Column>
                    </ColumnContainer>
                </ModalBody>
            </VModal>
        );
    }
}

export default PerformanceXmasModal;