import React, {Component} from 'react';
import styled from "styled-components";
import PlutoniumAnimation from "../Animations/PlutoniumAnimation";
import StyledButton from "../shared/StyledButton";
import PerformanceModal from "./PerformanceModal";
import PerformanceXmasModal from "./PerformanceXmasModal";
import { media } from 'utils/Media';

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @media ${media.md} {
        flex-direction: row;
    }
`

const Content = styled.div`
    text-align: center;
    background-color: black;
    padding: 2rem 0;
    
    h2 {
        color: white;
    }
`

class PerformanceBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            xmasModalOpen: false,
        }
    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    toggleXmasModal = (e) => {
        e.preventDefault();

        this.setState({
            xmasModalOpen: !this.state.xmasModalOpen
        });
    };

    render() {
        return (
            <Content className="my-3 my-md-5">
                <PlutoniumAnimation/>
                <h2 className="h1 mt-2 mb-4">Performance schedule</h2>
                <ButtonWrapper>
                <StyledButton onClick={this.toggleModal} button >
                    View schedule
                </StyledButton>
                {/* <StyledButton onClick={this.toggleXmasModal} button >
                    VIEW CHRISTMAS SCHEDULE
                </StyledButton> */}
                </ButtonWrapper>
                <PerformanceModal toggleModal={this.toggleModal} modalOpen={this.state.modalOpen}/>
                <PerformanceXmasModal toggleModal={this.toggleXmasModal} modalOpen={this.state.xmasModalOpen}/>
            </Content>
        );
    }
}

export default PerformanceBlock